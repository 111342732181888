import React from "react";
import PropTypes from "prop-types";
import { useParams, useLocation } from "react-router-dom";

import { request, plural, sleep } from "../components/Utils";
import StatementModal from "../components/modals/Statement";
import CardModal from "../components/modals/CardModal";
import EconomyModal from "../components/modals/EconomyModal";
import DepositModal from "../components/modals/DepositModal";
import CreditModal from "../components/modals/CreditModal";
import InsuranceModal from "../components/modals/InsuranceModal";
import TechDefoltModal, { getList } from "../components/modals/TechDefoltModal";
import HistoryModal from "../components/modals/HistoryModal";

import ModalInsuranceRealty from "../components/modals/insurance/Realty";
import ModalInsuranceHealth from "../components/modals/insurance/Health";
import ModalInsuranceCredit from "../components/modals/insurance/Credit";
import ModalInsuranceLife from "../components/modals/insurance/Life";
import ModalInsuranceCar from "../components/modals/insurance/Car";
import ModalInsuranceBusiness from "../components/modals/insurance/Business";

import EconomyIcon from "../img/EconomyIcon.svg";

import Dice from "../components/Dice";
import {
  Wrap,
  Container,
  Header,
  LogoBlock,
  LogoBCC,
  LogoGame,
  TitleBlock,
  Body,
  Sidebar,
  GameField,
  GameContainer,
  Parameter,
  ParameterName,
  ParameterValue,
  ParameterTitle,
  ParameterIcon,
  RegisterButton,
  ResultIconWrap,
  ResultTitle,
  ResultName,
  ResultValue,
  ResultDescription,
  GamePanel,
  GamePanelContainer,
  GamePanelColumn,
  GamePanelIcon,
  GamePanelTitle,
  GamePanelIcon2,
  GamePanelTitle2,
  GameButton,
  GameButtons,
  GameWrap,
  DiceField,
  DiceFieldBody,
  DiceFieldFoot,
  DiceFieldHead,
  RollButton,
  RollsHistoryButton,
  RollsRemain,
  TestRollButton,
  WeekIcon,
  StartFieldIcon,
  PanelInfo,
  UserChip,
  CashWrap,
  CashContainer,
  CashBox,
  RulesBody,
} from "../view/Game";

import Modal, {
  Container730,
  Container580,
  Container554,
  Head,
  Body as ModalBody,
  Row,
  Box,
  Text,
  Block,
  Button as ModalButton,
  ButtonsWrap,
  ButtonLink,
} from "../components/Modal";

import StartIcon from "../img/StartIcon.svg";
import LogoImg from "../img/Logo.svg";
import LogoBCCImg from "../img/LogoBCC.svg";

import WalletIcon from "../img/WalletIcon.svg";
import ExpensesIcon from "../img/ExpensesIcon.svg";
import IncomeIcon from "../img/IncomeIcon.svg";
import MoneyBoxIcon from "../img/MoneyBoxIcon.svg";
import ActivesIcon from "../img/ActivesIcon.svg";
import PassivesIcon from "../img/PassivesIcon.svg";
import RegisterIcon from "../img/RegisterIcon.svg";
import ResultIcon from "../img/ResultIcon.svg";

import RiskIcon from "../img/RiskIcon.svg";
import RiskBg from "../img/RiskBg.svg";

import OportunityIcon from "../img/OportunityIcon.svg";
import Oportunity2Icon from "../img/Oportunity2Icon.svg";
import Oportunity3Icon from "../img/Oportunity3Icon.svg";

import OportunityBg from "../img/OportunityBg.svg";
import Oportunity2Bg from "../img/Oportunity2Bg.svg";
import LotteryIcon from "../img/LotteryIcon.svg";
import LotteryBg from "../img/LotteryBg.svg";
import PremiumIcon from "../img/PremiumIcon.svg";
import PremiumBg from "../img/PremiumBg.svg";
import TraumaBg from "../img/TraumaBg.svg";
import RoamIcon from "../img/RoamIcon.svg";
import RoamBg from "../img/RoamBg.svg";
import StudyIcon from "../img/StudyIcon.svg";
import StudyBg from "../img/StudyBg.svg";
import ExpensesGameIcon from "../img/ExpensesGameIcon.svg";
import HistoryIcon from "../img/HistoryIcon.svg";
import CalendarIcon from "../img/CalendarIcon.svg";
import CalendarIconBlack from "../img/CalendarIconBlack.svg";
import TraumaIcon from "../img/TraumaIcon.svg";
import FemaleIcon from "../img/FemaleIcon.svg";
import MaleIcon from "../img/MaleIcon.svg";

const UserIcon = ({ app, data, panel }) => {
  return (
    <>
      {app.current.user && panel === data.panel && (
        <UserChip
          data-panel={panel}
          data-chip
          src={app.current.user.gender === "male" ? MaleIcon : FemaleIcon}
        />
      )}
    </>
  );
};

const Game = ({ app }) => {
  const location = useLocation();

  const { gameId } = useParams();
  const diceRoll = React.useRef(null);
  const dices = React.useRef([]);
  const [rolling, setRolling] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  const [insuranceModalMode, setInsuranceModalMode] = React.useState(null);

  const [rulesShown, setRulesShown] = React.useState(false);
  const [modalRegister, setModalRegister] = React.useState(false);
  const [registerMode, setRegisterMode] = React.useState("active");
  const [modalRollInit, setModalRollInit] = React.useState(false);
  const [modalCard, setModalCard] = React.useState(false);
  const [modalEconomy, setModalEconomy] = React.useState(false);
  const [modalDeposit, setModalDeposit] = React.useState(false);
  const [modalCredit, setModalCredit] = React.useState(false);

  const [modalInsurance, setModalInsurance] = React.useState(false);
  const [modalInsuranceLife, setModalInsuranceLife] = React.useState(false);
  const [modalInsuranceHealth, setModalInsuranceHealth] = React.useState(false);
  const [modalInsuranceCredit, setModalInsuranceCredit] = React.useState(false);
  const [modalInsuranceMortgage, setModalInsuranceMortgage] =
    React.useState(false);
  const [modalInsuranceRealty, setModalInsuranceRealty] = React.useState(false);
  const [modalInsuranceBusiness, setModalInsuranceBusiness] =
    React.useState(false);
  const [modalInsuranceCar, setModalInsuranceCar] = React.useState(false);

  const [modalTechDefolt, setModalTechDefolt] = React.useState(false);
  const [modalHistory, setModalHistory] = React.useState(false);
  const [modalEconomyNotify, setModalEconomyNotify] = React.useState(false);
  const [modalFinResultFormula, setModalFinResultFormula] =
    React.useState(false);

  const [modal, setModal] = React.useState(null);

  const refChip = React.useRef(null);
  const refWrap = React.useRef(null);

  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);
  const ref4 = React.useRef(null);
  const ref5 = React.useRef(null);
  const ref6 = React.useRef(null);
  const ref7 = React.useRef(null);
  const ref8 = React.useRef(null);
  const ref9 = React.useRef(null);
  const ref10 = React.useRef(null);
  const ref11 = React.useRef(null);
  const ref12 = React.useRef(null);

  //Загрузка всех необходимых данных
  React.useEffect(() => {
    const load = async () => {
      const resp = await request({
        url: "/api/game",
        params: {
          method: "GET",
          body: {
            id: gameId,
          },
        },
        app: app,
      });
      if (resp && resp.success) {
        setData(resp.result.data);
        if (resp.result.modal) {
          setModal(resp.result.modal);
        }
      }
    };
    load();
    // eslint-disable-next-line
  }, [app, gameId]);

  React.useEffect(() => {
    if (data && modal) {
      showModal(modal);
    }

    // eslint-disable-next-line
  }, [modal]);

  const rollInit = async () => {
    if (data.vars.movesLeft > 0 && data.status !== 1) {
      if (data && data.vars.movesCount % 5 === 0) {
        setModalRollInit(true);
      } else {
        start();
      }
    } else {
      //setModal({ type: "end" });
      start();
    }
  };

  const buttonAction = async (i, params) => {
    const resp = await request({
      url: "/api/game/action",
      params: {
        method: "GET",
        //redirect: "follow",
        body: {
          id: gameId,
          button: i,
          params: params,
          v: new URLSearchParams(location.search).get("v"),
        },
      },
      app: app,
    });
    if (resp.result.data) {
      let prevData = data;
      //Отображение страхования для нового бизнеса
      if (
        prevData != null &&
        prevData.move != null &&
        //Если вторая карточка из двух
        prevData.move.cards.length === 2 &&
        resp.result.data.move.cards.length === 1 &&
        //Если добавлен бизнес
        (Object.keys(prevData.vars.onlineBusiness).length <
          Object.keys(resp.result.data.vars.onlineBusiness).length ||
          Object.keys(prevData.vars.offlineBusiness).length <
            Object.keys(resp.result.data.vars.offlineBusiness).length) &&
        //Если текущая карточка - внешний фактор
        resp.result.modal.panel === 14
      ) {
        setModalInsuranceBusiness(true);
      }

      setData(resp.result.data);
    }
    if (resp.result.roll) {
      setModalCard(false);
      dices.current = [resp.result.roll];
      diceRoll.current();
      setTimeout(() => {
        //setExpanded(false);
        setRolling(false);
        if (resp.result.modal) {
          setModal(resp.result.modal);
        }
      }, 2000);
    } else {
      setModalCard(false);
      if (resp.result.modal) {
        setModal(resp.result.modal);
      }
    }
  };

  const toggleAction = async (params) => {
    const resp = await request({
      url: "/api/game/settings",
      params: {
        method: "POST",
        //redirect: "follow",
        body: {
          id: gameId,
          params,
        },
      },
      app: app,
    });
    if (resp.result.data) {
      setData(resp.result.data);
      setErrors(resp.errors);
    }
    return resp;
  };

  const showModal = async (modal) => {
    setModalCard(true);

    //Если нужны деньги и не может взять кредит
    //debugger;

    if (data) {
      const list = getList(data);

      if (
        (data.vars.cash < 0 ||
          (modal &&
            modal.cash > 0 &&
            ((data.vars.creditInsured &&
              modal.cash >
                data.vars.cash +
                  (data.vars.creditCardLimit - data.vars.creditCardCost) +
                  data.vars.maxCreditAmount) ||
              (!data.vars.creditInsured &&
                modal.cash >
                  data.vars.cash +
                    (data.vars.creditCardLimit - data.vars.creditCardCost) +
                    data.vars.maxCreditAmount -
                    data.vars.consumerCreditInsuranceExpense)))) &&
        list.length > 0
        /**
      modal &&
      modal.cash > 0 &&
      data &&
      data.vars.cash < modal.cash &&
      (Object.keys(data.vars.room) > 0 ||
        Object.keys(data.vars.flat) > 0 ||
        Object.keys(data.vars.staff) > 0 ||
        Object.keys(data.vars.car) > 0 ||
        Object.keys(data.vars.onlineBusiness) > 0 ||
        Object.keys(data.vars.offlineBusiness) > 0 ||
        Object.keys(data.vars.partnership) > 0) &&
      data.vars.creditCardLimit - data.vars.creditCardCost - modal.cash < 0 &&
      (!data.vars.creditInsured ||
        (data.vars.consumerCreditCost + modal.cash) *
          data.vars.consumerCreditRate >
          data.vars.allIncome / 2)
          **/
      ) {
        //Технический дефолт
        await sleep(1000);
        setModalTechDefolt(true);
      }
    }
  };

  const rollTheDice = async () => {
    let panels = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      12,
    ];

    let refs = {
      1: ref1,
      2: ref2,
      3: ref3,
      4: ref4,
      5: ref5,
      6: ref6,
      7: ref7,
      8: ref8,
      9: ref9,
      10: ref10,
      11: ref11,
      12: ref12,
    };

    const setChipPosition = (panel) => {
      const coords = refs[panel].current.getBoundingClientRect();
      refChip.current.style.top =
        coords.top + +(coords.height / 2) + refWrap.current.scrollTop + "px";
      refChip.current.style.left = coords.left + coords.width / 2 + "px";
      refs[panel].current.children[0].setAttribute("data-active", true);
    };

    if (!rolling) {
      setRolling(true);
      let prevPanel = data.panel ? data.panel : 1;
      if (data.panel === 13) {
        prevPanel = 6;
      }
      setChipPosition(prevPanel);

      const resp = await request({
        url: "/api/game/roll",
        params: {
          method: "GET",
          //redirect: "follow",
          body: {
            id: gameId,
            v: new URLSearchParams(location.search).get("v"),
            c: new URLSearchParams(location.search).get("c"),
          },
        },
        app: app,
      });
      if (resp && resp.success) {
        let prevData = data;

        setData(resp.result.data);

        //Крутим кубики
        if (resp.result.dices) {
          dices.current = resp.result.dices;
          diceRoll.current();
        }

        if (prevData && prevData.vars.movesLeft > 0 && prevData.status !== 1) {
          //Ждём завершения броска
          await sleep(3000);

          let currentPanel = resp.result.data.panel;
          if (currentPanel === 13) {
            currentPanel = 6;
          }
          //Проходим по полям от предыдущего до нужного

          //13 - если нечётное

          for (
            let panel = panels.indexOf(prevPanel);
            panel < panels.length;
            panel++
          ) {
            if (panel > 0 && refs[panels[panel - 1]] != null && refs[panels[panel - 1]].current != null) {
              refs[panels[panel - 1]].current.children[0].setAttribute(
                "data-active",
                false
              );
            }
            setChipPosition(panels[panel]);
            await sleep(500);
            if (panels[panel] === currentPanel) {
              break;
            }
          }

          //Ждём завершения броска
          await sleep(1000);

          //data.panel

          //Двигаем фишку по координатам центра элементов
          //Последовательность блоков (номеров блоков)
          //Проходим по ним, по порядку и начинаем сначала пока не попадём на нужную
          //Для каждого блока находим координаты центра блока и передвигаем туда фишку
          //Скрываем передвигаемую фишку. Показываем фишку внутри панели
          //Каждый раз расширяем панель
        }

        //setExpanded(false);
        if (resp.result.modal) {
          setModal(resp.result.modal);
        }
      }
      setRolling(false);
    }
  };

  const getStudy = async () => {
    const resp = await request({
      url: "/api/game/study",
      params: {
        method: "GET",
        //redirect: "follow",
        body: {
          id: gameId,
          c: new URLSearchParams(location.search).get("c"),
        },
      },
      app: app,
    });
    if (resp && resp.success) {
      setData(resp.result.data);
      if (resp.result.modal) {
        setModal(resp.result.modal);
      }
    }
  };

  React.useEffect(() => {
    if (insuranceModalMode !== null) {
      showInfoInsuranceModal();
    }
    // eslint-disable-next-line
  }, [insuranceModalMode]);

  const showInfoInsuranceModal = () => {
    if (data) {
      switch (data.vars.movesCount) {
        case 0:
          setModalInsuranceLife(true);
          break;
        case 1:
          setModalInsuranceHealth(true);
          break;
        case 2:
          setModalInsuranceRealty(true);
          break;
        case 3:
          if (Object.keys(data.vars.car).length > 0) {
            setModalInsuranceCar(true);
          } else {
            onInsuranceModalClose();
          }
          break;
        case 4:
          if (
            Object.keys(data.vars.onlineBusiness).length > 0 ||
            Object.keys(data.vars.offlineBusiness).length > 0
          ) {
            setModalInsuranceBusiness(true);
          } else {
            onInsuranceModalClose();
          }
          break;
        case 5:
          let mortgage = false;
          for (const k of Object.keys(data.vars.room)) {
            if (data.vars.room[k].credit && data.vars.room[k].credit > 0) {
              mortgage = true;
            }
          }
          for (const k of Object.keys(data.vars.flat)) {
            if (data.vars.flat[k].credit && data.vars.flat[k].credit > 0) {
              mortgage = true;
            }
          }
          if (mortgage) {
            //res = true;
            setModalInsuranceMortgage(true);
          } else {
            onInsuranceModalClose();
          }
          break;
        /*
        case 6:
          if (data.vars.consumerCreditCost > 0) {
            setModalInsuranceCredit(true);
          } else {
            onInsuranceModalClose();
          }
          break;
          */
        default:
          onInsuranceModalClose();
          break;
      }
    }
  };

  const start = () => {
    if (data.vars.movesLeft > 0 && data.status !== 1) {
      if (data.vars.movesCount < 7) {
        setInsuranceModalMode("info");
      } else {
        if (
          data.vars.fullEconomy &&
          (data.vars.movesCount === 7 || data.vars.monthlySavings < 0)
        ) {
          setModalEconomyNotify(true);
        } else {
          setModalRollInit(false);
          rollTheDice();
        }
      }
    } else {
      rollTheDice();
    }
  };

  const onInsuranceModalClose = () => {
    if (insuranceModalMode === "info") {
      setModalRollInit(false);
      rollTheDice();
    } else if (insuranceModalMode === "study") {
      getStudy();
    }
    setInsuranceModalMode(null);
  };
  //modalRollInit || modalEconomy || modalDeposit || modalCard || modalRegister || modalCredit || modalInsurance
  return (
    <>
      {data && data.vars && modalCard && (
        <CashWrap>
          <CashContainer>
            <CashBox>
              <Parameter>
                <ParameterIcon>
                  <img src={WalletIcon} alt="Wallet icon" />
                </ParameterIcon>
                <ParameterTitle>
                  <ParameterName>Наличные</ParameterName>
                  <ParameterValue>
                    {data.vars.cash.toLocaleString("ru-RU")} &#8381;
                  </ParameterValue>
                </ParameterTitle>
              </Parameter>
            </CashBox>
          </CashContainer>
        </CashWrap>
      )}
      <Wrap ref={refWrap} data-rolling={rolling}>
        <Container>
          <Header data-desktop="flex">
            <LogoBlock>
              <LogoBCC src={LogoBCCImg} alt="BCC logo" />
              <TitleBlock>
                <LogoGame src={LogoImg} alt="Logo" />
                <div>
                  Деловая игра по повышению
                  <br /> финансовой грамотности в сфере страхования
                </div>
              </TitleBlock>
            </LogoBlock>
            <ButtonsWrap>
              <ModalButton
                data-mr="10"
                data-mb="0"
                data-ml="0"
                onClick={() => setRulesShown((prevState) => !prevState)}
              >
                {rulesShown ? "К игре" : "Правила"}
              </ModalButton>
              <ButtonLink to="/cp">Выйти в меню</ButtonLink>
            </ButtonsWrap>
          </Header>
          <Header data-mobile="flex">
            <ButtonsWrap data-p="5">
              {rulesShown && (
                <ModalButton
                  data-mr="0"
                  data-mb="0"
                  data-ml="0"
                  onClick={() => setRulesShown((prevState) => !prevState)}
                >
                  К игре
                </ModalButton>
              )}
              {!rulesShown && <ButtonLink to="/cp">Выйти в меню</ButtonLink>}
            </ButtonsWrap>
            <LogoBlock>
              <LogoBCC src={LogoBCCImg} alt="BCC logo" />
              <LogoGame src={LogoImg} alt="Logo" />
            </LogoBlock>
          </Header>
          {rulesShown && (
            <>
              <RulesBody data-mt="30" data-mb="30">
                <Head data-align="left">Правила игры «Предел риска»</Head>
                <Block data-mt="10">
                  <strong>
                    Цель игры - достичь наибольшего совокупного финансового
                    результата игрока, складываемого из стоимости чистых активов
                    (стоимость всех активов за вычетом всех пассивов) и
                    10-кратного размера ежемесячных сбережений на момент
                    окончания игры.
                  </strong>
                </Block>
                <Block data-mt="10">
                  1. Игрок начинает игру с Зарплатой 40 000 р. в мес., Расходами
                  40 000 р. в мес., Стартовой квартирой (1 500 000 р.),
                  Автомобилем (500 000 р.) и Наличными (100 000 р.), имеется
                  задолженность по Кредитной карте (-100 000 р.) Волевым
                  решением игрок может увеличить сбережения до 30% от начального
                  уровня зарплаты (кнопка «Экономия»).{" "}
                  <strong>
                    При последующей полной отмене экономии игрок получает бонус
                    через 5 ходов – открывается дополнительно карточка
                    «Возможности» (не более 2 раз за игру).
                  </strong>
                </Block>
                <Block data-mt="10">
                  2. Игровое поле представляет собой 2 месяца. При прохождении
                  отметки «Начало месяца»{" "}
                  <img
                    src={CalendarIconBlack}
                    alt="Новый месяц"
                    style={{
                      width: "32px",
                      verticalAlign: "middle",
                    }}
                  />{" "}
                  игрок получает или оплачивает сумму месячных сбережений
                  (разностью между «Доходами» и «Расходами») наличными. При
                  нехватке средств можно воспользоваться кредитом.
                </Block>
                <Block data-mt="10">
                  3. Перед совершением хода доступны следующие операции –
                  кредитование, страхование, депозит и экономия. Также можно
                  открыть ведомость для анализа текущего финансового состояния.
                </Block>
                <Block data-mt="10">
                  4. При совершении хода (кнопка «Сделать ход») происходит
                  бросок визуализированного кубика (используется генератор
                  случайных чисел при определении значения), на котором выпадает
                  значение от 1 до 6. Фишка передвигается по полю на выпавшее
                  значение, начиная с отметки «Старт».
                </Block>
                <Block data-mt="10">
                  5. При попадании на клетку игрового поля открывается окно с
                  выпавшей карточкой (игровой ситуацией). Игровые карточки могут
                  быть двух видов: обязательные и не обязательные к исполнению.
                  Выпавшая карточка имеет варианты действий игрока в зависимости
                  от выпавшей ситуации.
                </Block>
                <Block data-mt="10">
                  6. В начале игры показываются информационные карточки о видах
                  страхования, которые можно приобрести.
                </Block>
                <Block data-mt="10">
                  7. Вместо хода в любое время можно пройти «Обучение» (не более
                  2 раз за игру).{" "}
                </Block>
                <Block data-mt="10">
                  8. Начиная с восьмого хода вместе с карточкой{" "}
                  <strong>«Возможности»</strong> в случайном порядке открывается
                  карточка <strong>«Внешний фактор».</strong>{" "}
                </Block>
                <Block data-mt="10">
                  9. При попадании на поле <strong>«Премия»</strong> игроку
                  выплачивается наличными Х0% от «Оплаты труда» (Зарплата и
                  подработка), где Х-цифра, выпавшая на кубике (например, 3 –
                  30%).
                </Block>
                <Block data-mt="10">
                  10. При попадании на поле <strong>«Лотерея»</strong> игрок
                  может сделать ставку наличными в сумме до 50 000 р. Розыгрыш
                  лотереи проводится путем предварительного фиксирования игроком
                  значения от 1 до 6 и броском кубика. При совпадении
                  зафиксированного числа и выпавшего значения на кубике – ставка
                  умножается на 5.
                </Block>
                <Block data-mt="10">
                  11. Все совершенные финансовые операции автоматически попадают
                  в электронную игровую ведомость, в которой производится
                  пересчет ежемесячных доходов, расходов, месячных сбережений,
                  активов и пассивов.
                </Block>
                <Block data-mt="10"></Block>

                <Head data-align="left" data-mt="30">
                  Доступные финансовые инструменты
                </Head>
                <Block data-mt="10">
                  <strong>Страхование.</strong> Перед совершением хода можно
                  приобрести любой страховой продукт, позволяющий защитить свое
                  здоровье, имущество и ответственность перед третьими лицами на
                  случай неблагоприятных ситуаций. При нажатии на
                  соответствующую кнопку открываются доступные варианты
                  страхования с учетом имеющихся активов. Обязательные виды
                  страхования заносятся автоматически в ведомость.
                </Block>
                <Block data-mt="10">
                  <strong>Депозит.</strong> Игрок может открыть депозит по
                  ставке 0,5% в месяц.
                </Block>
                <Block data-mt="10">
                  <strong>Кредит.</strong> В любой момент перед совершением хода
                  или в процессе игровой ситуации игроку доступны следующие виды
                  кредитов:
                  <br />
                  - ипотечный кредит по ставке 0,8% в месяц;
                  <br />
                  - автокредит по ставке 1% в месяц;
                  <br />
                  - потребительский кредит по ставке 2% в месяц (при страховании
                  потребительского кредита платеж за страховку осуществляется
                  единовременным платежом, что дает возможность впоследствии
                  увеличить сумму кредита, при отсутствии кредитного страхования
                  «добирать» потребительский кредит нельзя).
                  <br />
                  - кредитная карта с лимитом 200 000 р по ставке 2% в мес.,
                  возобновляемый кредитный лимит указывается в скобках.
                  <br />
                  <strong>
                    При получении потребительского кредита сумма выплат по всем
                    кредитам не должна превышать 50% общего дохода.
                  </strong>
                  <br />
                  <strong>
                    Кредиты в игровой ведомости не имеют сроков, т.е.
                    выплачиваются только проценты во время игры, пока не будет
                    погашено «тело» кредита.
                  </strong>{" "}
                  При этом игрок вправе в любой момент погасить основной долг по
                  кредиту полностью или частично, уменьшив ежемесячный платеж
                  процентов.
                </Block>
              </RulesBody>
              <br />
            </>
          )}
          {!rulesShown && data != null && (
            <Body>
              <Sidebar>
                <Parameter
                  data-mobile="flex"
                  onClick={() => {
                    setModalFinResultFormula(true);
                  }}
                  data-cursor="pointer"
                >
                  <ResultIconWrap>
                    <img src={ResultIcon} alt="Result icon" />
                  </ResultIconWrap>
                  <ResultTitle>
                    <ResultName>Фин.результат</ResultName>
                    <ResultValue>
                      {data.vars.finResult.toLocaleString("ru-RU")} &#8381;
                    </ResultValue>
                  </ResultTitle>
                </Parameter>
                <Parameter data-mobile="flex">
                  <RegisterButton onClick={() => setModalRegister(true)}>
                    <img src={RegisterIcon} alt="Register icon" />
                    Ведомость
                  </RegisterButton>
                </Parameter>
                <Parameter>
                  <ParameterIcon>
                    <img src={WalletIcon} alt="Wallet icon" />
                  </ParameterIcon>
                  <ParameterTitle>
                    <ParameterName>Наличные</ParameterName>
                    <ParameterValue>
                      {data.vars.cash.toLocaleString("ru-RU")} &#8381;
                    </ParameterValue>
                  </ParameterTitle>
                </Parameter>
                <Parameter>
                  <ParameterIcon>
                    <img src={MoneyBoxIcon} alt="Money box icon" />
                  </ParameterIcon>
                  <ParameterTitle>
                    <ParameterName>Мес.сбережения</ParameterName>
                    <ParameterValue>
                      {data.vars.monthlySavings.toLocaleString("ru-RU")} &#8381;
                    </ParameterValue>
                  </ParameterTitle>
                </Parameter>
                <Parameter>
                  <ParameterIcon>
                    <img src={IncomeIcon} alt="Income icon" />
                  </ParameterIcon>
                  <ParameterTitle>
                    <ParameterName>Доходы</ParameterName>
                    <ParameterValue>
                      {data.vars.allIncome.toLocaleString("ru-RU")} &#8381;
                    </ParameterValue>
                  </ParameterTitle>
                </Parameter>
                <Parameter>
                  <ParameterIcon>
                    <img src={ExpensesIcon} alt="Expensees icon" />
                  </ParameterIcon>
                  <ParameterTitle>
                    <ParameterName>Расходы</ParameterName>
                    <ParameterValue>
                      {data.vars.allExpenses.toLocaleString("ru-RU")} &#8381;
                    </ParameterValue>
                  </ParameterTitle>
                </Parameter>
                <Parameter>
                  <ParameterIcon>
                    <img src={ActivesIcon} alt="Actives icon" />
                  </ParameterIcon>
                  <ParameterTitle>
                    <ParameterName>Активы</ParameterName>
                    <ParameterValue>
                      {data.vars.allActiveCost.toLocaleString("ru-RU")} &#8381;
                    </ParameterValue>
                  </ParameterTitle>
                </Parameter>
                <Parameter>
                  <ParameterIcon>
                    <img src={PassivesIcon} alt="Passives icon" />
                  </ParameterIcon>
                  <ParameterTitle>
                    <ParameterName>Пассивы</ParameterName>
                    <ParameterValue>
                      {data.vars.allPassivesCost.toLocaleString("ru-RU")}{" "}
                      &#8381;
                    </ParameterValue>
                  </ParameterTitle>
                </Parameter>
                <Parameter data-desktop="flex">
                  <RegisterButton onClick={() => setModalRegister(true)}>
                    <img src={RegisterIcon} alt="Register icon" />
                    Ведомость
                  </RegisterButton>
                </Parameter>
                <Parameter data-desktop="flex">
                  <ResultIconWrap>
                    <img src={ResultIcon} alt="Result icon" />
                  </ResultIconWrap>
                  <ResultTitle>
                    <ResultName>Фин.результат</ResultName>
                    <ResultValue>
                      {data.vars.finResult.toLocaleString("ru-RU")} &#8381;
                    </ResultValue>
                  </ResultTitle>
                  <ResultDescription>
                    Формула расчета
                    <br />
                    Чистые активы + 10 мес. сбережений
                  </ResultDescription>
                </Parameter>
              </Sidebar>
              <GameField>
                <UserChip
                  data-moving-chip
                  ref={refChip}
                  src={
                    app.current.user.gender === "male" ? MaleIcon : FemaleIcon
                  }
                />
                <GameContainer>
                  <GameWrap>
                    <GamePanelColumn>
                      <GamePanel ref={ref10}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${RiskBg})` }}
                        >
                          <GamePanelIcon src={RiskIcon} alt="Risk icon" />
                          <GamePanelTitle>Риск</GamePanelTitle>
                          <UserIcon
                            app={app}
                            data={data}
                            panel={10}
                            static={true}
                          />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref9}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${OportunityBg})` }}
                        >
                          <GamePanelIcon src={OportunityIcon} alt="Risk icon" />
                          <GamePanelTitle>Возможности</GamePanelTitle>
                          <UserIcon app={app} data={data} panel={9} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref8}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${LotteryBg})` }}
                        >
                          <GamePanelIcon src={LotteryIcon} alt="Risk icon" />
                          <GamePanelTitle>Лотерея</GamePanelTitle>
                          <UserIcon app={app} data={data} panel={8} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref7}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${OportunityBg})` }}
                        >
                          <GamePanelIcon src={OportunityIcon} alt="Risk icon" />
                          <GamePanelTitle>Возможности</GamePanelTitle>
                          <PanelInfo>
                            <WeekIcon src={CalendarIcon} />
                          </PanelInfo>
                          <UserIcon app={app} data={data} panel={7} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref6}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${TraumaBg})` }}
                        >
                          <GamePanelIcon
                            src={ExpensesGameIcon}
                            alt="Risk icon"
                            data-expenses
                          />
                          <GamePanelTitle data-trauma>Травма</GamePanelTitle>
                          <GamePanelIcon2
                            src={TraumaIcon}
                            alt="Risk icon"
                            data-trauma
                          />
                          <GamePanelTitle2 data-expenses>
                            Расходы
                          </GamePanelTitle2>
                          <UserIcon app={app} data={data} panel={6} data-chip />
                          <UserIcon
                            app={app}
                            data={data}
                            panel={13}
                            data-chip
                          />
                        </GamePanelContainer>
                      </GamePanel>
                    </GamePanelColumn>
                    <GamePanelColumn>
                      <GamePanel ref={ref11}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${OportunityBg})` }}
                        >
                          <GamePanelIcon
                            src={Oportunity2Icon}
                            alt="Risk icon"
                            data-oportunity
                          />
                          <GamePanelTitle>Возможности</GamePanelTitle>
                          <UserIcon
                            app={app}
                            data={data}
                            panel={11}
                            data-chip
                          />
                        </GamePanelContainer>
                      </GamePanel>
                      <DiceField>
                        <DiceFieldHead data-desktop="flex">
                          <RollsHistoryButton
                            onClick={() => setModalHistory(true)}
                          >
                            <img src={HistoryIcon} alt="Rolls history" />
                            История ходов
                          </RollsHistoryButton>
                          <RollsRemain>
                            Оcталось:{" "}
                            {`${data.vars.movesLeft} ${plural(
                              data.vars.movesLeft,
                              ["ход", "хода", "ходов"]
                            )}`}
                          </RollsRemain>
                        </DiceFieldHead>
                        <DiceFieldBody>
                          <Dice roll={diceRoll} dices={dices} />
                        </DiceFieldBody>
                        <DiceFieldFoot data-desktop="flex">
                          <TestRollButton
                            onClick={() => {
                              if (data.vars.movesCount < 7) {
                                setInsuranceModalMode("study");
                              } else {
                                getStudy();
                              }
                            }}
                          >
                            Обучение вместо хода{" "}
                            <span>{data.vars.studyInsteadMove}</span>
                          </TestRollButton>
                          <RollButton onClick={rollInit}>
                            {data &&
                            (data.vars.movesLeft === 0 || data.status === 1)
                              ? "Завершить игру"
                              : "Сделать ход"}
                          </RollButton>
                        </DiceFieldFoot>
                        <RollsRemain data-mobile="block">
                          Оcталось:{" "}
                          {`${data.vars.movesLeft} ${plural(
                            data.vars.movesLeft,
                            ["ход", "хода", "ходов"]
                          )}`}
                        </RollsRemain>
                      </DiceField>
                      <GamePanel ref={ref5}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${PremiumBg})` }}
                        >
                          <GamePanelIcon
                            src={PremiumIcon}
                            alt="Risk icon"
                            data-premium
                          />
                          <GamePanelTitle>Премия</GamePanelTitle>
                          <UserIcon app={app} data={data} panel={5} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                    </GamePanelColumn>
                    <GamePanelColumn>
                      <GamePanel ref={ref12}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${RoamBg})` }}
                        >
                          <GamePanelIcon
                            src={RoamIcon}
                            alt="Risk icon"
                            data-roam
                          />
                          <GamePanelTitle>Разгуляй</GamePanelTitle>
                          <UserIcon
                            app={app}
                            data={data}
                            panel={12}
                            data-chip
                          />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref1}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${Oportunity2Bg})` }}
                        >
                          <GamePanelIcon
                            src={Oportunity3Icon}
                            alt="Risk icon"
                          />
                          <GamePanelTitle>Возможности</GamePanelTitle>
                          <PanelInfo>
                            <StartFieldIcon>Старт</StartFieldIcon>
                            <WeekIcon src={CalendarIcon} data-ml="10" />
                          </PanelInfo>
                          <UserIcon app={app} data={data} panel={1} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref2}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${StudyBg})` }}
                        >
                          <GamePanelIcon src={StudyIcon} alt="Study icon" />
                          <GamePanelTitle>Обучение</GamePanelTitle>
                          <UserIcon app={app} data={data} panel={2} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref3}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${Oportunity2Bg})` }}
                        >
                          <GamePanelIcon
                            src={Oportunity3Icon}
                            alt="Risk icon"
                          />
                          <GamePanelTitle>Возможности</GamePanelTitle>
                          <UserIcon app={app} data={data} panel={3} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                      <GamePanel ref={ref4}>
                        <GamePanelContainer
                          style={{ backgroundImage: `url(${RiskBg})` }}
                        >
                          <GamePanelIcon src={RiskIcon} alt="Risk icon" />
                          <GamePanelTitle>Риск</GamePanelTitle>
                          <UserIcon app={app} data={data} panel={4} data-chip />
                        </GamePanelContainer>
                      </GamePanel>
                    </GamePanelColumn>
                  </GameWrap>
                  <DiceFieldHead data-mobile="flex">
                    <RollsHistoryButton
                      onClick={() => setModalHistory(true)}
                      data-w="50"
                      data-mr="5"
                    >
                      <img src={HistoryIcon} alt="Rolls history" />
                      История ходов
                    </RollsHistoryButton>
                    <RollsHistoryButton
                      onClick={() => setRulesShown(true)}
                      data-mr="0"
                      data-w="50"
                    >
                      Правила
                    </RollsHistoryButton>
                  </DiceFieldHead>
                </GameContainer>
                <div data-mobile="flex" data-direction="column" data-mt="5">
                  <TestRollButton
                    onClick={() => {
                      if (data.vars.movesCount < 7) {
                        setInsuranceModalMode("study");
                      } else {
                        getStudy();
                      }
                    }}
                  >
                    Обучение вместо хода{" "}
                    <span>{data.vars.studyInsteadMove}</span>
                  </TestRollButton>
                  <RollButton onClick={rollInit}>
                    {data && (data.vars.movesLeft === 0 || data.status === 1)
                      ? "Завершить игру"
                      : "Сделать ход"}
                  </RollButton>
                </div>
                <GameButtons>
                  <GameButton onClick={() => setModalCredit(true)}>
                    Кредитование
                  </GameButton>
                  <GameButton onClick={() => setModalInsurance(true)}>
                    Страхование
                  </GameButton>
                  <GameButton onClick={() => setModalDeposit(true)}>
                    Депозит
                  </GameButton>
                  <GameButton onClick={() => setModalEconomy(true)}>
                    Экономия
                  </GameButton>
                </GameButtons>
              </GameField>
            </Body>
          )}
        </Container>
      </Wrap>

      <Modal show={modalFinResultFormula} valign="center">
        <Container554>
          <Row data-align="center" data-mb="20">
            Формула расчета финансового результата: <br />
            (Активы – Пассивы) + 10 мес. сбережений
          </Row>
          <Row data-align="center">
            <ModalButton
              data-type="green"
              onClick={() => {
                setModalFinResultFormula(false);
              }}
              data-mobile-w="100"
              data-mb="0"
              data-mx="0"
            >
              Закрыть
            </ModalButton>
          </Row>
        </Container554>
      </Modal>

      <Modal show={modalRollInit} valign="center">
        <Container730>
          <Head>
            Перед новым ходом, рассмотрите имеющиеся варианты действий
          </Head>
          <ModalBody>
            <Row
              data-align="center"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
            >
              <ModalButton
                data-type="blue"
                onClick={() => setModalInsurance(true)}
              >
                Страхование
              </ModalButton>
              <ModalButton
                data-type="blue"
                onClick={() => setModalRegister(true)}
              >
                Погасить кредит
              </ModalButton>
              <ModalButton
                data-type="blue"
                onClick={() => setModalDeposit(true)}
              >
                Депозит
              </ModalButton>
            </Row>
            <Row
              data-align="center"
              data-mt="40"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
            >
              <ModalButton onClick={start}>
                <img src={StartIcon} alt="Start icon" data-mr="10" />
                Сделать ход
              </ModalButton>
            </Row>
          </ModalBody>
        </Container730>
      </Modal>

      {data && data.vars && (
        <>
          <EconomyModal
            show={modalEconomy}
            hide={() => setModalEconomy(false)}
            data={data}
            toggleAction={toggleAction}
          />

          <DepositModal
            show={modalDeposit}
            hide={() => setModalDeposit(false)}
            data={data}
            toggleAction={toggleAction}
          />
          {modalCard && (
            <>
              <CardModal
                show={modalCard}
                hide={() => setModalCard(false)}
                modal={modal}
                data={data}
                action={buttonAction}
                setModalRegister={setModalRegister}
                setRegisterMode={setRegisterMode}
                setModalCredit={setModalCredit}
              />
              {modal.overlay && (
                <CardModal
                  show={modalCard}
                  hide={() => setModalCard(false)}
                  modal={modal.overlay}
                  data={data}
                  action={buttonAction}
                  setModalRegister={setModalRegister}
                  setModalCredit={setModalCredit}
                />
              )}
            </>
          )}

          <Modal show={modalEconomyNotify} valign="center">
            <Container554>
              <Row data-align="center" data-mb="20">
                <img src={EconomyIcon} alt="Economy icon" />
              </Row>
              <Row data-align="center">
                Если вы хотите сократить свои расходы на жизнь, то волевым
                решением вы можете начать экономить в соответствующей вкладке.
                Однако это лишит вас возможности вытянуть две дополнительные
                карточки “Возможности” по ходу игры.
              </Row>
              <Row
                data-align="center"
                data-mt="40"
                data-ml="-5"
                data-mr="-5"
                data-mb="-10"
              >
                <ModalButton
                  data-type="green"
                  onClick={() => {
                    setModalRollInit(false);
                    rollTheDice();
                    setModalEconomyNotify(false);
                  }}
                >
                  К игре
                </ModalButton>
                <ModalButton
                  data-type="blue"
                  onClick={() => {
                    setModalEconomyNotify(false);
                    setModalEconomy(true);
                  }}
                >
                  Экономия
                </ModalButton>
              </Row>
            </Container554>
          </Modal>

          <CreditModal
            show={modalCredit}
            hide={() => setModalCredit(false)}
            data={data}
            toggleAction={toggleAction}
            setModalInsurance={setModalInsurance}
            setModalInsuranceCredit={setModalInsuranceCredit}
            setModalRegister={setModalRegister}
            setRegisterMode={setRegisterMode}
          />
          <InsuranceModal
            show={modalInsurance}
            hide={() => {
              setModalInsurance(false);
            }}
            data={data}
            toggleAction={toggleAction}
            mode={insuranceModalMode}
            setModalInsuranceLife={setModalInsuranceLife}
            setModalInsuranceHealth={setModalInsuranceHealth}
            setModalInsuranceCredit={setModalInsuranceCredit}
            setModalInsuranceMortgage={setModalInsuranceMortgage}
            setModalInsuranceRealty={setModalInsuranceRealty}
            setModalInsuranceBusiness={setModalInsuranceBusiness}
            setModalInsuranceCar={setModalInsuranceCar}
          />

          <HistoryModal
            show={modalHistory}
            hide={() => setModalHistory(false)}
            data={data}
          />
          <StatementModal
            show={modalRegister}
            hide={() => setModalRegister(false)}
            data={data}
            toggleAction={toggleAction}
            registerMode={registerMode}
            setRegisterMode={setRegisterMode}
          />

          <ModalInsuranceHealth
            show={modalInsuranceHealth}
            hide={() => {
              setModalInsuranceHealth(false);
              onInsuranceModalClose();
            }}
            action={toggleAction}
            data={data}
            mode={insuranceModalMode}
          />

          <ModalInsuranceCredit
            show={modalInsuranceCredit}
            errors={errors}
            hide={() => {
              setModalInsuranceCredit(false);
              onInsuranceModalClose();
            }}
            action={toggleAction}
            data={data}
            mode={insuranceModalMode}
            setModalTechDefolt={setModalTechDefolt}
          />

          <ModalInsuranceLife
            show={modalInsuranceLife}
            hide={() => {
              setModalInsuranceLife(false);
              onInsuranceModalClose();
            }}
            action={toggleAction}
            data={data}
            mode={insuranceModalMode}
          />

          <Modal show={modalInsuranceMortgage} valign="center">
            <Container580>
              <Head data-align="left">Страхование ипотеки</Head>
              <Body data-mt="20">
                <Box>
                  <Row data-align="left">
                    При ипотечном страховании в обязательном порядке заключается
                    договор страхования Вашей недвижимости, купленной в ипотеку,
                    от пожара, стихийных бедствий, залива, противоправных
                    действий третьих лиц (грабеж, кража и т.п.).
                    <br />
                    <br />
                    Страховой взнос: 0,02% в месяц.
                    <br />
                    <br />
                    Необходимо страховать недвижимость на полную стоимость.
                  </Row>
                  <Row data-align="left" data-mt="20" data-mx="-10">
                    <Box
                      data-round="10"
                      data-bg="lightBlue"
                      data-p="10"
                      data-w="100"
                      data-direction="row"
                    >
                      <Box
                        data-round="10"
                        data-bg="lightGray"
                        data-p="10"
                        data-h="40"
                        data-valign="center"
                        data-w="50"
                        data-halign="center"
                        data-mr="5"
                      >
                        <Text data-text="17">
                          <strong>Стоимость недвижимости</strong>
                        </Text>
                      </Box>
                      <Box
                        data-round="10"
                        data-bg="lightGray"
                        data-p="10"
                        data-h="40"
                        data-valign="center"
                        data-w="50"
                        data-halign="center"
                        data-ml="5"
                      >
                        <Text data-text="17">
                          <strong>Взносы в месяц</strong>
                        </Text>
                      </Box>
                    </Box>
                  </Row>
                  <Row data-align="left" data-mt="10" data-mx="-10">
                    <Box
                      data-round="10"
                      data-bg="lightBlue"
                      data-p="10"
                      data-w="100"
                      data-direction="row"
                    >
                      <Box
                        data-round="10"
                        data-bg="lightGray"
                        data-p="10"
                        data-h="40"
                        data-valign="center"
                        data-w="50"
                        data-halign="center"
                        data-mr="5"
                      >
                        <Text data-text="17">1 000 000 &#8381;</Text>
                      </Box>
                      <Box
                        data-round="10"
                        data-bg="lightGray"
                        data-p="10"
                        data-h="40"
                        data-valign="center"
                        data-w="50"
                        data-halign="center"
                        data-ml="5"
                      >
                        <Text data-text="17">200 &#8381;</Text>
                      </Box>
                    </Box>
                  </Row>
                  <Row data-align="left" data-mt="10" data-mx="-10">
                    <Box
                      data-round="10"
                      data-bg="lightBlue"
                      data-p="10"
                      data-w="100"
                      data-direction="row"
                    >
                      <Box
                        data-round="10"
                        data-bg="lightGray"
                        data-p="10"
                        data-h="40"
                        data-valign="center"
                        data-w="50"
                        data-halign="center"
                        data-mr="5"
                      >
                        <Text data-text="17">2 000 000 &#8381;</Text>
                      </Box>
                      <Box
                        data-round="10"
                        data-bg="lightGray"
                        data-p="10"
                        data-h="40"
                        data-valign="center"
                        data-w="50"
                        data-halign="center"
                        data-ml="5"
                      >
                        <Text data-text="17">400 &#8381;</Text>
                      </Box>
                    </Box>
                  </Row>
                  <Row
                    data-align="center"
                    data-mt="40"
                    data-ml="-5"
                    data-mr="-5"
                    data-mb="-10"
                  >
                    <ModalButton
                      data-type="green"
                      onClick={() => {
                        setModalInsuranceMortgage(false);
                        onInsuranceModalClose();
                      }}
                    >
                      {["info", "study"].includes(insuranceModalMode)
                        ? "Понятно"
                        : "Назад"}
                    </ModalButton>
                  </Row>
                </Box>
              </Body>
            </Container580>
          </Modal>

          <ModalInsuranceRealty
            show={modalInsuranceRealty}
            hide={() => {
              setModalInsuranceRealty(false);
              onInsuranceModalClose();
            }}
            action={toggleAction}
            data={data}
            mode={insuranceModalMode}
          />

          <ModalInsuranceCar
            show={modalInsuranceCar}
            hide={() => {
              setModalInsuranceCar(false);
              onInsuranceModalClose();
            }}
            action={toggleAction}
            data={data}
            mode={insuranceModalMode}
          />

          <ModalInsuranceBusiness
            show={modalInsuranceBusiness}
            hide={() => {
              setModalInsuranceBusiness(false);
              onInsuranceModalClose();
            }}
            action={toggleAction}
            data={data}
            mode={insuranceModalMode}
          />

          <TechDefoltModal
            show={modalTechDefolt}
            hide={() => setModalTechDefolt(false)}
            data={data}
            toggleAction={toggleAction}
          />
        </>
      )}
    </>
  );
};

Game.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Game;
